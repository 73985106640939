import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { ContentContext } from '../ContentProvider';


export const Volgymajor = () => {
  const content = useContext(ContentContext);

  return (
    <Container fluid className='p-1'>
      <Row className='pt-1'>
        <Col className='mx-2'>
          <Container fluid className='p-3 ml-3'>
            <p>{content.accommodation.intro} {content.accommodation.parking}</p>

            {content.text.extended ? 
            ''
            :
            <p>Szombaton 13:30-tól tudjátok elfoglalni a szobátokat. </p>
            }
            <p>Vasárnap a szállásokat 12 óráig kérjük hagyjátok el. </p>

            {content.accommodation.prepayed &&
                    <p>A helyszíni szállásért nem kell fizetni.</p>
            }

            {content.accommodation?.cancellation}

            {content.text.extended  &&
                    <p>A helyszíni reggeliztetésben és a dekor végső simításaiban minden segítő kezet szívesen fogadunk. &#128522;</p>
            }

          </Container>

        </Col>
      </Row>

    </Container>
  )
}
