import React, { useContext } from 'react'
import { ContentContext } from './ContentProvider';

export const Contact = () => {

  const content = useContext(ContentContext);

  return (
    <div>
      {Array.from(content.text.contact).map((t)=>{
        return <p className='p-0 m-0'>{t}</p>
      })}
    </div>
  )
}
