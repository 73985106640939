import Modal from 'react-bootstrap/Modal';
import { ContentContext } from '../../ContentProvider';
import { useContext } from 'react';

import img_birdview from '../../../images/koborlo_szallo.jpg'
import img_rooms from '../../../images/koborlo_szallo_szoba.jpg'

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

export const Koborlo = (props) => {
  const content = useContext(ContentContext);


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          KóborLó Szállás
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 pb-2 m-0'>
      <Carousel>
          <Carousel.Item>
            <Image src={img_birdview} style={{width:'100%'}}></Image>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          {content.text.extended ? 
              <Carousel.Item>
                <Image src={img_rooms} style={{width:'100%'}}></Image>
                <Carousel.Caption>
                  <h3>2-4 fős szobák saját mosdóval.</h3>
                  <h3>Ágynemű biztosított.</h3>
                </Carousel.Caption>
              </Carousel.Item>
              
           : 
           ''}
          
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

