import React, { useContext } from 'react'
import Container from 'react-bootstrap/esm/Container'
import { ContentContext } from '../ContentProvider';

export const Welcome = () => {
  const content = useContext(ContentContext);

  return (
    <Container fluid className='m-0 p-0'>
        <h1>Kedves {content?.text?.userName}!</h1>
        <div>
            <p className='pt-1 m-0'>{content?.info?.welcome}</p>
            <p>{content?.text?.DateLong} ({content?.text?.DateDay})</p>
            <p>{content?.text?.Place}</p>
        </div>
    </Container>
  )
}
