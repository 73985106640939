import React, { useContext } from 'react'
import { Container } from 'react-bootstrap'

import { ContentContext } from '../ContentProvider';

import { VaczHotel } from './VaczHotel'
import { Volgymajor } from './Volgymajor'
import { Recommendation } from './Recommendation'
import { Kaboca } from './Kaboca'
import { Dunapart } from './Dunapart'

import { SzallasTitle } from '../titles/SzallasTitle';


export const Accommodation = () => {
  const content = useContext(ContentContext);

  const components = {
    'vacz_hotel': VaczHotel,
    'volgymajor': Volgymajor,
    'kaboca': Kaboca,
    'dunapart': Dunapart,
    'recommendation': Recommendation,

  };

  const SelectedComponent = components[content.accommodation.reference];

  return (
    <Container fluid className='p-0'>
      <SzallasTitle/>
      {SelectedComponent ? <SelectedComponent /> : <Recommendation /> }
    </Container>
  )
}
