import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentContext } from '../ContentProvider';
import { ANagyNapTitle } from '../titles/ANagyNapTitle';


export const Schedule = () => {
  const content = useContext(ContentContext);

  return (

    <Container fluid>
      <Container style={{maxWidth:'600px'}}>
      <Row className=''>
        <ANagyNapTitle/>
        <p>{content?.text?.DateLong}</p>
      </Row>
      <Row >
        {
          content.timeline?.map(function (item) {
            
            return <Row>
              <Row style={{maxWidth:"100%"}}>
                <Col><p style={{ fontWeight: 'bold' }}>{item?.title}</p></Col>
                <Col style={{textAlign: 'right'}}><p>{item?.time}</p></Col>
              </Row>
            </Row>;
          })
        }

      </Row>
    </Container>
    </Container>

  )
}
