import Modal from 'react-bootstrap/Modal';
import { ContentContext } from '../../ContentProvider';
import { useContext } from 'react';

import img_breakfast from '../../../images/kobor_macska.jpg'
import img_rooms from '../../../images/macska_szallas.jpg'

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

export const Macska = (props) => {
  const content = useContext(ContentContext);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Kóbor Macska Fogadó
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 pb-2 m-0'>
        <Carousel>
          <Carousel.Item>
            <Image src={img_breakfast} style={{width:'100%'}}></Image>
            <Carousel.Caption>
              <h3>{content.text.extended ? 'Svédasztalos reggeli' : ''}</h3>
            </Carousel.Caption>
          </Carousel.Item>

          {content.text.extended &&
          <Carousel.Item>
            <Image src={img_rooms} style={{width:'100%'}}></Image>
            <Carousel.Caption>
              <h3>2-3 fős szobák saját fürdőszobával.</h3>
              <h3>Ágynemű biztosított.</h3>
            </Carousel.Caption>
          </Carousel.Item>
          }

        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

