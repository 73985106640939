import React from 'react'
import { Container, Row } from 'react-bootstrap'

export const Recommendation = () => {

  return (
    <Container fluid className='p-1'>
      <Row className='pt-1'>
        <p>Feljegyzéseink szerint nem kértetek szállást, vagy magatoknak intézitek.</p>
        <p>Ha mégis szeretnétek segítséget, kérünk keressetek minket bátran!</p>
      </Row>

    </Container>
  )
}
