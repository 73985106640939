import React, { useContext, useEffect, useState } from 'react'

import { AuthContext } from "./Auth";
import { ContentContext } from "./ContentProvider"
import { useParams } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';

import { useNavigate } from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';


export const Login = () => {

  let { userId } = useParams({userId: ''});
  const [loginAlert, setLoginAlert] = useState('')
  const [loggingInProgress, setLoggingInProgress] = useState(true);
  const [formUserId, setFormUserId] = useState(''),
        onInput = ({target:{value}}) => setFormUserId(value);

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const currentUser = useContext(AuthContext);
  const content = useContext(ContentContext);
  const navigate = useNavigate();

  const login = (uid) => {
    setLoggingInProgress(true);
    if (uid === "") {
      setLoginAlert('Empty token!')
      setLoggingInProgress(false);
      return Promise.reject();
    }
    return currentUser.loginWithUID(uid).then(
      ()=>{
          setLoginAlert('')
          return content.fetchContent().then(()=>{
            setLoggingInProgress(false);
            navigate('/home');
            return Promise.reject();
          }).catch(()=>{
            setLoginAlert('Login error!')
            setLoggingInProgress(false);
            return Promise.reject();
          })
      }).catch((failure)=>{
        setLoginAlert(failure)
        setLoggingInProgress(false);
        return Promise.reject();
      });
  }

  const onSubmit = () => {
    setLoggingInProgress(true);
    return login(formUserId).catch(()=>{});
  };

  useEffect(() => {
    if (currentUser.currentUser) {
      setLoggingInProgress(true);
      console.debug("Active logged in")
      content.fetchContent().then(()=>{
        setLoggingInProgress(false);
        navigate('/home')
      }).catch(()=>{
        setLoginAlert('Login error!')
        setLoggingInProgress(false);
      });
    } else if (userId && userId !== '') {
      handleSubmit(login(userId).catch(()=>{}));
    } else {
      setLoginAlert('No token is provided yet.')
      setLoggingInProgress(false);
    }
  }, [currentUser, userId, content, navigate, handleSubmit, login]);

  return (
    <Container className='pt-2'>
      { isSubmitting | loggingInProgress?
        <Container>
          <h4>Logging in...</h4>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      :
        <Container>
          <Alert>
            <h4>Kérlek használd a linket, amit az e-mailben kaptál!</h4>
            <p className='text-muted'>Vagy a kódot a link végén:</p>

            <Row className="justify-content-md-center">
              <Col xs lg="6">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <InputGroup>
                    <InputGroup.Text id="uid">rekadaniel.hu/login/</InputGroup.Text>
                    <Form.Control
                      name="uid"
                      type="username"
                      placeholder="Felhasznaloi kod"
                      aria-label="Felhasznaloi kod"
                      onChange={onInput}
                    />
                  </InputGroup>
                  <Button variant="primary" type="submit" className='mt-2'>
                    Belépés
                  </Button>
                </Form>
              </Col>
            </Row>
            
            <p className='text-muted'>Ha a hiba a linkkel sem tűnik el, kérlek keress meg minket emailben / telefonon.</p>
            <p className='text-muted'>Error: {loginAlert}</p>
           </Alert>
        </Container>
      }
    
    </Container>

  )
}
