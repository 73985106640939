import { functions, auth } from '../config/firebase';

import React, {useEffect, useState} from "react";

import { httpsCallable } from "firebase/functions";
import { signInWithCustomToken } from "firebase/auth";

const authCall = httpsCallable(functions, 'authCall');
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState("");

    useEffect(()=>{
        auth.onAuthStateChanged(setCurrentUser)
    }, []);

    async function loginWithUID(userId){
        if(userId === undefined){
            return Promise.reject("Auth error.")
        }

        return authCall({uid: userId}).then((r)=>{
            if (r.data.status=='success') {
                const token = r.data.customToken
                return signInWithCustomToken(auth, token)
            }else{
                console.debug('Auth error.', userId, r.data)
                return Promise.reject("Auth error. Invalid credentials.")
            }
            
        }).catch((r)=>{
            console.debug('Auth error', r?.data)
            return Promise.reject("Auth error.")
        });
        
        
    }

    const logout = () => {
        return auth.signOut()
    }

    return (
        <AuthContext.Provider value={{currentUser, logout, loginWithUID}} >
            {children}
        </AuthContext.Provider>
    );
};