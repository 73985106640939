
import { functions } from '../config/firebase';

import React, {useContext, useState} from "react";

import { httpsCallable } from "firebase/functions";
import { AuthContext } from './Auth';

const getContent = httpsCallable(functions, 'getContent');

export const ContentContext = React.createContext();

export const ContentProvider = ({ children }) => {

    const [isContentLoaded, setContentLoaded] = useState(false);

    const [text, setText] = useState({
        Welcome: ['Loading...'],
        infopage: 'Loading...',
        form: undefined,
        contact: ['Loading...'],
        WelcomeAdditional: ['Loading...'],
        DateDay: 'Loading...',
        DateLong: 'Loading...',
        InfoPageGettingThere: 'Loading...',
        InfoPageFriday: 'Loading...',
        InfoPagePlace: ['Loading...', '...'],
        Place: '',
    })

    const [accommodation, setAccomodation] = useState();
    const [timeline, setTimeline] = useState();
    const [info, setInfo] = useState();


    const currentUser = useContext(AuthContext);


    async function fetchContent(){
        if(!currentUser.currentUser){
            return Promise.reject('Not Authenticated.')
        }
        if (isContentLoaded) {
            return Promise.reject('Content is loaded already.')
        }
        return Promise.all([getContent()]).then((results)=>{
            const text = results[0];
            setText(text.data);
            setAccomodation(text.data.accommodation);
            setTimeline(text.data.timeline);
            setInfo(text.data.info)
            setContentLoaded(true);
        });
    }

    if (currentUser.currentUser && !isContentLoaded) {
        fetchContent()
    }

    return (
        <ContentContext.Provider value={{text, accommodation, timeline, info, fetchContent}} >
            {children}
        </ContentContext.Provider>
    );
};

