import Modal from 'react-bootstrap/Modal';

import img from '../../../images/ceremonia_kapolna.jpg'
import { Image } from 'react-bootstrap';

export const Kapolna = (props) => {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Kápolna
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 pb-2 m-0'>
        <Image src={img} style={{width:'100%'}}></Image>
      </Modal.Body>
    </Modal>
  );
}

