import { Container } from "react-bootstrap"

export const ANagyNapTitle = () => {

    return (
        <Container className='pt-3 pb-2' style={{maxWidth:"250px", maxHeight:'80px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 572 122">
            <path id="Selection" fill="#5D7052" stroke="#5D7052" strokeWidth="2" 
            d="M 26.00,0.00
            C 26.00,0.00 37.00,0.00 37.00,0.00
              37.00,0.00 47.98,36.00 47.98,36.00
              47.98,36.00 66.00,95.00 66.00,95.00
              66.00,95.00 51.00,95.00 51.00,95.00
              51.00,95.00 51.00,93.00 51.00,93.00
              51.00,93.00 55.00,93.00 55.00,93.00
              55.00,93.00 40.00,47.00 40.00,47.00
              41.25,62.41 43.96,85.18 26.00,92.52
              19.19,95.30 7.42,95.00 0.00,95.00
              8.36,75.06 13.60,53.67 20.02,33.00
              21.50,28.25 27.45,10.67 27.38,7.00
              27.34,4.91 26.47,2.11 26.00,0.00 Z
            M 30.00,11.00
            C 26.24,15.67 23.42,28.72 21.33,35.00
              21.33,35.00 10.28,71.00 10.28,71.00
              10.28,71.00 4.00,93.00 4.00,93.00
              11.55,93.00 18.90,93.65 26.00,90.47
              36.47,85.79 38.98,74.39 39.00,64.00
              39.04,39.29 34.48,33.22 30.00,11.00 Z
            M 273.00,30.00
            C 266.25,17.60 270.95,11.76 284.00,10.00
              284.00,10.00 286.00,18.00 286.00,18.00
              281.78,18.36 273.85,19.39 273.70,25.02
              273.60,29.24 280.29,36.12 282.47,40.00
              287.47,48.89 286.80,61.92 280.47,69.98
              270.46,82.73 257.52,78.14 248.02,82.92
              243.27,85.31 243.87,88.01 247.08,88.39
              249.16,88.63 258.46,86.00 261.00,85.29
              264.47,84.33 268.40,83.30 272.00,83.09
              280.90,82.58 284.99,86.04 284.99,95.00
              284.99,99.52 284.47,102.31 281.47,105.90
              273.13,115.87 253.98,117.59 242.00,116.15
              236.68,115.52 231.48,114.86 227.11,111.36
              224.70,109.44 223.25,107.15 223.21,104.00
              223.10,94.21 234.57,91.17 242.00,89.00
              241.09,83.66 242.77,82.81 247.00,80.00
              239.51,77.50 234.56,75.68 229.56,69.00
              221.35,58.03 223.97,40.19 235.04,32.22
              247.20,23.47 259.85,25.62 273.00,30.00 Z
            M 108.00,48.00
            C 108.65,40.83 111.86,33.83 118.00,29.70
              127.20,23.52 142.03,25.73 148.30,35.00
              151.63,39.94 151.99,45.29 152.00,51.00
              152.00,51.00 152.00,93.00 152.00,93.00
              152.00,93.00 157.00,93.00 157.00,93.00
              157.00,93.00 157.00,95.00 157.00,95.00
              157.00,95.00 145.00,95.00 145.00,95.00
              136.31,94.88 133.66,91.72 135.52,83.00
              135.52,83.00 139.97,70.00 139.97,70.00
              143.23,59.70 145.23,43.29 138.35,34.04
              130.61,23.63 118.56,30.06 112.99,39.00
              108.82,45.70 108.44,53.43 107.91,61.00
              107.91,61.00 107.00,71.00 107.00,71.00
              107.00,71.00 107.00,93.00 107.00,93.00
              107.00,93.00 110.00,95.00 110.00,95.00
              110.00,95.00 94.00,95.00 94.00,95.00
              94.00,95.00 94.00,93.00 94.00,93.00
              94.00,93.00 98.00,93.00 98.00,93.00
              98.00,93.00 98.00,29.00 98.00,29.00
              98.00,29.00 94.00,29.00 94.00,29.00
              94.00,29.00 94.00,27.00 94.00,27.00
              94.00,27.00 107.00,27.00 107.00,27.00
              107.00,27.00 108.00,48.00 108.00,48.00 Z
            M 168.00,30.00
            C 173.66,27.83 180.89,25.74 187.00,26.09
              189.75,26.25 194.41,27.18 197.00,28.10
              216.61,35.06 217.00,54.72 217.00,72.00
              217.00,72.00 217.00,93.00 217.00,93.00
              217.00,93.00 221.00,93.00 221.00,93.00
              221.00,93.00 221.00,95.00 221.00,95.00
              221.00,95.00 207.00,95.00 207.00,95.00
              207.00,95.00 207.00,88.00 207.00,88.00
              196.51,94.40 187.42,98.09 175.00,93.90
              165.87,90.81 157.78,82.17 161.43,72.00
              165.46,60.73 183.44,58.52 191.78,48.96
              200.34,39.15 191.96,28.46 185.00,28.42
              180.85,28.40 174.44,33.30 170.00,35.00
              170.00,35.00 168.00,30.00 168.00,30.00 Z
            M 325.00,88.00
            C 329.70,74.44 334.17,70.92 334.00,56.00
              333.91,48.55 331.02,37.76 335.09,31.04
              338.67,25.13 345.63,24.87 347.66,29.15
              348.81,31.59 347.69,35.53 346.96,38.00
              346.96,38.00 340.42,56.00 340.42,56.00
              340.42,56.00 325.85,92.00 325.85,92.00
              323.02,98.59 320.71,105.92 314.96,110.61
              309.38,115.16 301.92,115.98 295.00,116.00
              295.00,116.00 295.00,107.00 295.00,107.00
              308.83,107.00 313.78,108.15 322.00,95.00
              322.00,95.00 317.64,94.01 317.64,94.01
              317.64,94.01 310.40,79.00 310.40,79.00
              310.40,79.00 298.15,49.00 298.15,49.00
              298.15,49.00 290.00,27.00 290.00,27.00
              290.00,27.00 304.00,27.00 304.00,27.00
              304.00,27.00 304.00,29.00 304.00,29.00
              304.00,29.00 300.00,29.00 300.00,29.00
              300.00,29.00 325.00,88.00 325.00,88.00 Z
            M 392.00,46.00
            C 395.83,21.58 423.41,22.05 431.87,35.02
              435.21,40.14 435.97,47.01 436.00,53.00
              436.00,53.00 436.00,93.00 436.00,93.00
              436.00,93.00 440.00,93.00 440.00,93.00
              440.00,93.00 440.00,95.00 440.00,95.00
              435.48,95.00 423.72,95.84 420.43,93.15
              415.39,89.02 420.33,78.90 422.00,74.00
              425.98,62.29 430.88,39.71 418.82,30.88
              409.88,24.33 399.42,32.80 395.49,41.00
              390.27,51.92 391.00,63.30 391.00,75.00
              391.00,83.36 390.10,87.00 393.00,95.00
              393.00,95.00 377.00,95.00 377.00,95.00
              377.00,95.00 377.00,93.00 377.00,93.00
              377.00,93.00 382.00,93.00 382.00,93.00
              382.00,93.00 382.00,29.00 382.00,29.00
              382.00,29.00 377.00,29.00 377.00,29.00
              377.00,29.00 377.00,27.00 377.00,27.00
              377.00,27.00 391.00,27.00 391.00,27.00
              391.00,27.00 392.00,46.00 392.00,46.00 Z
            M 453.00,35.00
            C 451.81,27.91 457.16,27.67 463.00,26.62
              473.01,24.81 483.72,27.66 490.91,35.04
              501.35,45.77 500.00,56.39 500.00,70.00
              500.00,70.00 500.00,93.00 500.00,93.00
              500.00,93.00 505.00,93.00 505.00,93.00
              505.00,93.00 505.00,95.00 505.00,95.00
              505.00,95.00 491.00,95.00 491.00,95.00
              491.00,95.00 491.00,87.00 491.00,87.00
              480.87,95.54 469.63,97.96 457.00,93.29
              448.56,90.17 441.87,82.48 444.57,73.00
              448.44,59.40 472.76,57.92 477.99,45.00
              481.36,36.70 474.46,25.80 465.00,29.14
              459.97,30.91 459.31,33.29 453.00,35.00 Z
            M 534.00,28.00
            C 536.31,27.16 538.52,26.40 541.00,26.19
              555.40,24.96 567.78,35.51 571.24,49.00
              572.33,53.24 572.05,61.36 572.00,66.00
              571.92,72.56 570.08,78.63 566.21,84.00
              559.03,93.96 542.89,99.55 532.00,92.30
              526.55,88.67 524.66,84.60 522.00,79.00
              522.00,79.00 522.00,122.00 522.00,122.00
              522.00,122.00 509.00,121.00 509.00,121.00
              509.00,121.00 513.00,121.00 513.00,121.00
              513.00,121.00 513.00,29.00 513.00,29.00
              513.00,29.00 509.00,29.00 509.00,29.00
              509.00,29.00 509.00,27.00 509.00,27.00
              509.00,27.00 521.00,27.00 521.00,27.00
              523.29,27.01 526.73,26.87 528.54,28.17
              533.36,31.29 530.00,40.95 528.54,45.00
              522.99,58.69 518.41,74.91 530.09,86.96
              533.07,90.02 536.74,92.60 541.00,93.46
              555.28,96.34 562.87,75.10 563.00,64.00
              563.15,50.95 560.84,35.01 547.00,29.23
              541.70,27.01 538.62,29.31 534.00,28.00 Z
            M 252.00,28.33
            C 236.59,32.03 231.96,48.05 235.51,62.00
              238.08,72.07 247.08,80.46 258.00,78.67
              283.50,74.49 282.81,25.01 252.00,28.33 Z
            M 337.00,59.00
            C 337.00,59.00 344.10,37.00 344.10,37.00
              344.60,34.90 345.62,30.55 343.40,29.08
              340.02,26.84 336.18,32.26 335.43,35.00
              334.14,39.70 335.01,54.34 337.00,59.00 Z
            M 523.00,56.00
            C 526.15,42.19 536.55,29.51 522.00,29.00
              522.00,29.00 523.00,56.00 523.00,56.00 Z
            M 196.00,31.00
            C 195.17,36.58 199.98,42.23 193.72,50.00
              185.53,60.19 171.48,60.97 168.53,74.00
              166.34,83.71 174.54,92.87 184.00,93.80
              194.43,94.83 201.09,86.80 204.54,78.00
              208.52,67.82 208.02,49.79 203.01,40.00
              201.14,36.35 198.78,33.93 196.00,31.00 Z
            M 479.00,31.00
            C 479.00,31.00 478.00,31.00 478.00,31.00
              478.00,31.00 479.00,32.00 479.00,32.00
              479.00,32.00 479.00,31.00 479.00,31.00 Z
            M 480.00,32.00
            C 479.25,38.46 483.62,41.83 477.44,49.99
              470.58,59.05 457.12,60.86 453.01,71.00
              447.54,84.49 461.70,98.05 475.00,92.85
              482.22,90.03 487.10,82.20 489.08,75.00
              492.90,61.12 491.76,41.81 480.00,32.00 Z
            M 40.00,43.00
            C 40.00,43.00 39.00,43.00 39.00,43.00
              39.00,43.00 40.00,44.00 40.00,44.00
              40.00,44.00 40.00,43.00 40.00,43.00 Z
            M 427.00,66.00
            C 424.75,70.31 418.31,86.57 421.02,90.69
              422.46,92.87 424.70,92.81 427.00,93.00
              427.00,93.00 427.00,66.00 427.00,66.00 Z
            M 143.00,68.00
            C 140.62,71.89 134.80,86.60 137.30,90.57
              138.65,92.71 140.77,92.73 143.00,93.00
              143.00,93.00 143.00,68.00 143.00,68.00 Z
            M 260.00,88.29
            C 251.96,89.62 240.11,90.08 234.42,96.33
              225.91,105.66 236.92,116.48 251.00,114.71
              258.51,113.77 267.67,111.00 272.61,104.96
              276.23,100.52 277.71,92.53 271.87,89.17
              268.97,87.51 263.28,87.98 260.00,88.29 Z">
            </path>
            </svg>
        </Container>
    )
}