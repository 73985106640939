import { Container } from "react-bootstrap"

export const HelyszinTitle = () => {

    return (
        <Container className='pt-3 pb-2' style={{maxWidth:"250px", maxHeight:'80px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 522 122">
            <path id="Selection" fill="#5D7052" stroke="#5D7052" strokeWidth="2" 
            d="M 420.00,16.00
            C 421.66,11.12 424.88,7.77 428.42,4.06
              430.00,2.41 432.17,0.06 434.71,1.17
              436.12,1.78 436.36,2.87 437.00,4.00
              437.00,4.00 420.00,16.00 420.00,16.00 Z
            M 64.00,1.00
            C 64.00,1.00 83.00,1.00 83.00,1.00
              83.00,1.00 83.00,3.00 83.00,3.00
              83.00,3.00 79.00,3.00 79.00,3.00
              79.00,11.65 78.06,36.19 80.00,43.00
              83.33,33.23 91.40,30.00 101.00,30.00
              104.18,30.00 106.99,29.97 110.00,31.23
              113.13,32.53 115.53,34.76 118.00,37.00
              118.00,37.00 118.00,3.00 118.00,3.00
              118.00,3.00 113.00,1.00 113.00,1.00
              113.00,1.00 133.00,1.00 133.00,1.00
              133.00,1.00 133.00,3.00 133.00,3.00
              133.00,3.00 128.85,3.00 128.85,3.00
              128.85,3.00 128.85,49.54 128.85,49.54
              128.85,49.54 128.00,99.00 128.00,99.00
              128.00,99.00 133.00,100.00 133.00,100.00
              133.00,100.00 113.00,99.00 113.00,99.00
              113.00,99.00 118.00,99.00 118.00,99.00
              118.00,99.00 118.00,70.00 118.00,70.00
              118.00,61.74 119.96,44.27 114.72,38.04
              103.32,24.50 84.72,33.72 80.11,47.00
              78.98,50.24 79.02,52.65 79.00,56.00
              78.92,73.83 86.25,80.51 86.90,91.00
              87.44,99.61 83.01,99.99 76.00,100.00
              72.01,100.01 67.75,100.49 64.00,99.00
              64.00,99.00 68.00,99.00 68.00,99.00
              68.00,99.00 68.00,3.00 68.00,3.00
              68.00,3.00 64.00,3.00 64.00,3.00
              64.00,3.00 64.00,1.00 64.00,1.00 Z
            M 205.00,1.00
            C 205.00,1.00 219.00,1.00 219.00,1.00
              219.00,1.00 219.00,99.00 219.00,99.00
              219.00,99.00 224.00,100.00 224.00,100.00
              224.00,100.00 205.00,99.00 205.00,99.00
              205.00,99.00 209.00,99.00 209.00,99.00
              209.00,99.00 209.00,3.00 209.00,3.00
              209.00,3.00 205.00,3.00 205.00,3.00
              205.00,3.00 205.00,1.00 205.00,1.00 Z
            M 202.00,73.00
            C 200.67,79.52 198.31,85.93 193.79,90.96
              182.33,103.72 160.60,104.45 148.18,92.68
              141.51,86.35 138.11,76.02 138.00,67.00
              137.83,51.82 142.30,36.91 158.00,31.09
              172.50,25.72 197.06,30.62 195.96,50.00
              194.98,67.10 162.92,68.00 160.23,84.00
              158.86,92.17 166.31,102.66 177.00,97.31
              186.26,92.69 190.63,82.71 192.00,73.00
              192.00,73.00 202.00,73.00 202.00,73.00 Z
            M 265.00,94.00
            C 265.00,94.00 274.05,68.00 274.05,68.00
              275.69,58.10 270.65,43.62 274.65,35.02
              277.74,28.37 284.88,28.07 287.40,30.60
              289.50,32.70 288.89,36.42 288.24,39.00
              288.24,39.00 280.95,60.00 280.95,60.00
              280.95,60.00 265.42,98.00 265.42,98.00
              262.45,104.96 260.15,112.27 253.91,117.06
              247.90,121.68 241.21,121.99 234.00,122.00
              234.00,122.00 234.00,112.00 234.00,112.00
              247.50,115.28 254.71,111.67 262.00,100.00
              262.00,100.00 256.70,99.01 256.70,99.01
              256.70,99.01 249.40,83.00 249.40,83.00
              249.40,83.00 228.00,30.00 228.00,30.00
              228.00,30.00 243.00,31.00 243.00,31.00
              243.00,31.00 239.00,31.00 239.00,31.00
              239.00,31.00 265.00,94.00 265.00,94.00 Z
            M 340.00,44.00
            C 330.53,26.37 309.10,28.27 305.33,39.84
              303.35,45.93 310.49,51.75 315.00,54.53
              325.76,61.18 347.16,65.48 351.06,79.00
              353.78,88.47 346.08,95.14 338.00,98.12
              325.00,102.93 307.23,102.54 297.10,91.96
              292.96,87.63 290.42,82.07 290.73,76.00
              290.97,71.19 293.78,67.61 299.00,68.66
              309.48,70.77 310.14,88.59 318.10,95.77
              326.35,103.21 344.61,92.09 341.13,83.01
              339.40,78.50 333.11,74.96 329.00,72.78
              317.74,66.80 296.33,59.82 295.61,45.00
              295.23,37.17 302.30,32.44 309.00,30.44
              321.49,26.70 337.85,29.17 346.00,40.00
              346.00,40.00 340.00,44.00 340.00,44.00 Z
            M 458.00,51.00
            C 459.06,44.12 461.42,38.01 467.04,33.53
              476.42,26.05 493.17,27.71 499.87,38.01
              502.96,42.77 503.93,49.42 504.00,55.00
              504.00,55.00 504.00,99.00 504.00,99.00
              504.00,99.00 508.00,100.00 508.00,100.00
              503.83,100.00 490.41,100.94 487.55,98.26
              484.84,95.36 486.25,88.46 487.55,85.00
              492.07,70.33 494.19,69.44 494.00,53.00
              493.80,36.61 482.81,24.37 469.02,35.39
              466.63,37.30 464.91,39.38 463.35,42.00
              455.16,55.76 457.00,82.90 457.00,99.00
              457.00,99.00 460.00,100.00 460.00,100.00
              460.00,100.00 443.00,99.00 443.00,99.00
              443.00,99.00 448.00,99.00 448.00,99.00
              448.00,99.00 448.00,31.00 448.00,31.00
              448.00,31.00 443.00,30.00 443.00,30.00
              443.00,30.00 457.00,30.00 457.00,30.00
              457.00,30.00 458.00,51.00 458.00,51.00 Z
            M 160.00,95.00
            C 159.07,90.88 157.56,87.29 158.53,83.00
              162.16,66.96 183.68,67.34 187.44,53.00
              190.32,42.03 183.58,31.64 172.00,31.04
              148.75,29.84 145.02,61.08 149.13,78.00
              150.80,84.86 153.35,91.69 160.00,95.00 Z
            M 277.00,64.00
            C 277.00,64.00 284.34,41.00 284.34,41.00
              285.09,38.59 286.76,33.90 284.34,32.01
              280.69,29.32 276.47,34.89 275.57,38.00
              274.29,42.44 275.32,59.76 277.00,64.00 Z
            M 359.00,30.00
            C 359.00,30.00 411.00,30.00 411.00,30.00
              411.00,30.00 394.67,57.00 394.67,57.00
              394.67,57.00 367.00,99.00 367.00,99.00
              367.00,99.00 395.00,99.00 395.00,99.00
              395.00,99.00 406.98,97.49 406.98,97.49
              406.98,97.49 414.00,92.00 414.00,92.00
              414.00,92.00 414.00,100.00 414.00,100.00
              414.00,100.00 355.00,100.00 355.00,100.00
              355.00,100.00 368.95,77.00 368.95,77.00
              368.95,77.00 399.00,31.00 399.00,31.00
              392.06,31.00 371.31,30.15 366.02,32.51
              363.20,33.77 361.50,36.30 359.00,38.00
              359.00,38.00 359.00,30.00 359.00,30.00 Z
            M 419.00,30.00
            C 419.00,30.00 438.00,31.00 438.00,31.00
              438.00,31.00 433.00,31.00 433.00,31.00
              433.00,31.00 433.00,99.00 433.00,99.00
              433.00,99.00 438.00,100.00 438.00,100.00
              438.00,100.00 419.00,99.00 419.00,99.00
              419.00,99.00 423.00,99.00 423.00,99.00
              423.00,99.00 423.00,31.00 423.00,31.00
              423.00,31.00 419.00,30.00 419.00,30.00 Z
            M 316.00,98.00
            C 316.00,98.00 309.15,84.00 309.15,84.00
              307.47,80.09 305.36,74.22 301.82,71.74
              298.42,69.36 294.37,70.02 292.74,74.06
              289.29,82.59 299.85,96.64 316.00,98.00 Z
            M 80.00,73.00
            C 80.00,73.00 79.00,99.00 79.00,99.00
              90.88,94.02 81.58,83.10 80.00,73.00 Z
            M 494.00,73.00
            C 491.94,76.81 486.96,89.95 487.74,93.94
              488.43,97.49 491.04,98.01 494.00,99.00
              494.00,99.00 494.00,73.00 494.00,73.00 Z
            M 161.00,95.00
            C 161.00,95.00 160.00,95.00 160.00,95.00
              160.00,95.00 161.00,96.00 161.00,96.00
              161.00,96.00 161.00,95.00 161.00,95.00 Z
            M 317.00,98.00
            C 317.00,98.00 316.00,98.00 316.00,98.00
              316.00,98.00 317.00,99.00 317.00,99.00
              317.00,99.00 317.00,98.00 317.00,98.00 Z">
            </path>
            </svg>
        </Container>
    )
}