import React, { useEffect, useRef, useState } from 'react'
import Container from 'react-bootstrap/esm/Container'

import img_foreground from '../images/landing_layer_0.png'
import img_background from '../images/landing_layer_background.png'

import { Image } from 'react-bootstrap';
import { Accommodation } from './accomodations/Accommodation';
import { Schedule } from './homepage/Schedule';
import { QA } from './homepage/QA';
import { Contact } from './Contact';
import { Location } from './homepage/Location';
import { Welcome } from './homepage/Welcome';

import img_volgymajor from '../images/volgymajor_mobile.png'


export const HomeAlternative = () => {

  const [previousPosition, setPreviousPosition] = useState(0);

  const [yImgForeground, setYImgForeground] = useState(0);
  const [foregroundSpeed, setForegroundSpeed] = useState(0.5);

  const [yWelcome, setYWelcome] = useState(0);
  const [welcomeSpeed, setWelcomeSpeed] = useState(1.2);



  const [yContent, setYContent] = useState(100);

  const headerRef = useRef();

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    const isForwardDirection = (currentPosition - previousPosition) >= 0
    const diff = currentPosition - previousPosition

    setYContent(headerRef.current.offsetHeight)
    setPreviousPosition(currentPosition)
    setYImgForeground(yImgForeground + (diff * foregroundSpeed))
    setYWelcome(yWelcome + (diff * welcomeSpeed))


  };

  useEffect(() => {
    if (!headerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setYContent(headerRef.current.offsetHeight)
    })
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect();
  }, [headerRef, yContent]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [previousPosition, headerRef, yContent]);

  return (
    <Container
      onScroll={handleScroll}
      fluid
      className='p-0 m-0'
      style={{ position: 'relative', width: '100%' }}>

      <Image src={img_foreground}
        className='p-0 m-0'
        width='100%'
        style={{
          position: 'absolute',
          top: `${yImgForeground}px`, left: '0px', zIndex: 3
        }} />
      <Image src={img_background}
        className='p-0 m-0'
        ref={headerRef}
        width='100%'
        style={{
          position: 'absolute',
          top: '0px', left: '0px', zIndex: 2
        }} />

      <Image src={img_background}
        className='p-0 m-0'
        ref={headerRef}
        width='100%'
        style={{
          position: 'absolute',
          top: '0px', left: '0px', zIndex: 0,
          backgroundColor: '#f9f2e8'
        }} />

      <Container
        className='p-0 m-0'
        width='30%'
        style={{
          color: '#5D7052',
          position: 'absolute',
          width: '40%',
          top: `${yWelcome + 90}px`, left: '5%', zIndex: 1
        }}>
        <Welcome
        ></Welcome>
      </Container>

      <Container
        fluid
        className='p-0 m-0'
        style={{
          color: "#6A645A",
          position: 'absolute',
          top: `${yContent - 20}px`,
          left: '0px',
          zIndex: 3,
        }}>

        <div style={{ height: '30px', transform: 'scale(1)', fill: '#f6ecdc' }}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '30px', transform: 'scale(1)', fill: '#f6ecdc' }} viewBox="0 0 1080 137" preserveAspectRatio="none">
            <path d="M 0,137 V 59.03716 c 158.97703,52.21241 257.17659,0.48065 375.35967,2.17167 118.18308,1.69101 168.54911,29.1665 243.12679,30.10771 C 693.06415,92.25775 855.93515,29.278599 1080,73.61449 V 137 Z" style={{ opacity: 0.99 }}></path>
            <path d="M 0,10.174557 C 83.419822,8.405668 117.65911,41.78116 204.11379,44.65308 290.56846,47.52499 396.02558,-7.4328 620.04248,94.40134 782.19141,29.627636 825.67279,15.823104 1080,98.55518 V 137 H 0 Z" style={{ opacity: 0.5 }}></path>
            <path  d="M 0,45.10182 C 216.27861,-66.146913 327.90348,63.09813 416.42665,63.52904 504.94982,63.95995 530.42054,22.125806 615.37532,25.210412 700.33012,28.295019 790.77619,132.60682 1080,31.125744 V 137 H 0 Z" style={{ opacity: 0.25 }}></path>
          </svg>
        </div>
        <Container fluid className='pt-2' style={{ backgroundColor: '#f6ecdc' }}>
          <Container>
            <Accommodation className='mb-5' />
          </Container>
        </Container>

        <Container fluid className='pt-2' style={{ backgroundColor: '#f6ecdc' }}>
          <Container>
            <Location className='mb-5' />
          </Container>
        </Container>


        <Container fluid className='pt-2' style={{ backgroundColor: '#f6ecdc' }}>
          <Container>
            <Schedule className='mb-5' />
          </Container>
        </Container>
        <div style={{ height: '30px', transform: 'scale(1, 1)', fill: '#f6ecdc', backgroundColor: '#f1e0c6' }}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '30px', transform: 'scale(1, -1)', fill: '#f6ecdc' }} viewBox="0 0 1080 137" preserveAspectRatio="none">
            <path d="M 0,137 V 59.03716 c 158.97703,52.21241 257.17659,0.48065 375.35967,2.17167 118.18308,1.69101 168.54911,29.1665 243.12679,30.10771 C 693.06415,92.25775 855.93515,29.278599 1080,73.61449 V 137 Z" style={{ opacity: 0.99 }}></path>
            <path d="M 0,10.174557 C 83.419822,8.405668 117.65911,41.78116 204.11379,44.65308 290.56846,47.52499 396.02558,-7.4328 620.04248,94.40134 782.19141,29.627636 825.67279,15.823104 1080,98.55518 V 137 H 0 Z" style={{ opacity: 0.5 }}></path>
            <path d="M 0,45.10182 C 216.27861,-66.146913 327.90348,63.09813 416.42665,63.52904 504.94982,63.95995 530.42054,22.125806 615.37532,25.210412 700.33012,28.295019 790.77619,132.60682 1080,31.125744 V 137 H 0 Z" style={{ opacity: 0.2 }}></path>
          </svg>
        </div>

        <Container fluid className='pt-2' style={{ backgroundColor: '#f1e0c6' }}>
          <Container>
            <QA className='mb-5' />
            <div style={{ height: "200px" }}>
              <Contact></Contact>
            </div>
          </Container>

          
        </Container>
        <Image src={img_volgymajor} style={{position: 'relative',  width: '100%'}}></Image>


      </Container>

    </Container>

  )
}
