import { Container } from "react-bootstrap"

export const QATitle = () => {

    return (
        <Container className='pt-3 pb-2' style={{maxWidth:"250px", maxHeight:'80px'}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 572 122">
            <path id="Selection" fill="#5D7052" stroke="#5D7052" strokeWidth="2" 
            d="M 193.00,107.00
            C 176.77,105.28 165.00,87.81 161.66,73.00
              155.74,46.68 162.21,8.58 193.00,1.43
              197.16,0.46 200.71,-0.13 205.00,0.04
              251.35,1.86 257.16,67.79 234.53,94.99
              227.85,103.03 219.13,106.98 209.00,108.56
              199.28,110.08 200.56,106.62 191.00,112.00
              203.65,112.00 218.71,111.97 231.00,115.00
              229.15,117.55 227.17,120.35 224.00,121.36
              220.36,122.53 205.76,116.35 200.00,115.44
              191.40,114.08 185.26,115.45 177.00,117.00
              177.00,117.00 193.00,107.00 193.00,107.00 Z
            M 201.00,107.00
            C 204.18,106.99 206.89,107.11 210.00,106.21
              219.42,103.51 225.88,95.58 229.82,87.00
              236.63,72.16 237.71,50.83 234.11,35.00
              232.81,29.26 230.93,23.08 227.91,18.00
              225.62,14.14 222.52,10.18 219.00,7.39
              212.90,2.56 201.45,-1.11 195.70,6.21
              192.20,10.66 193.13,16.05 194.75,21.00
              197.28,28.74 207.13,43.93 211.56,52.00
              218.25,64.17 223.20,80.36 214.89,93.00
              210.58,99.57 206.17,101.87 201.00,107.00 Z
            M 281.00,46.00
            C 276.41,44.47 272.98,43.57 269.17,40.32
              259.14,31.74 259.70,15.00 270.09,6.94
              281.47,-1.89 302.45,-0.54 311.61,11.01
              314.24,14.33 314.83,17.09 316.00,21.00
              316.00,21.00 308.00,23.00 308.00,23.00
              306.44,18.33 305.86,14.00 302.61,10.04
              301.00,8.09 299.21,6.62 297.00,5.41
              274.21,-7.04 260.46,32.64 280.00,42.55
              283.63,44.38 288.04,44.00 292.00,44.00
              292.00,44.00 292.00,47.00 292.00,47.00
              286.01,47.49 281.12,48.62 276.00,51.96
              260.58,62.04 259.18,87.06 272.18,99.70
              282.62,109.85 300.35,109.05 308.00,96.00
              302.12,93.75 298.37,90.50 294.04,86.00
              291.06,82.90 288.67,79.95 286.90,76.00
              277.10,54.16 303.12,43.57 317.90,56.39
              322.99,60.80 324.92,67.49 325.00,74.00
              325.11,83.55 324.18,89.37 318.00,97.00
              318.00,97.00 335.00,101.00 335.00,101.00
              335.00,101.00 332.00,109.00 332.00,109.00
              329.10,107.77 317.06,102.13 315.00,102.05
              312.40,101.94 306.11,105.73 302.00,106.84
              292.98,109.28 280.73,108.96 272.00,105.54
              253.09,98.14 245.24,75.33 257.93,59.09
              264.63,50.50 271.64,49.50 281.00,46.00 Z
            M 368.00,1.00
            C 368.00,1.00 379.30,1.99 379.30,1.99
              379.30,1.99 382.58,9.00 382.58,9.00
              382.58,9.00 387.72,26.00 387.72,26.00
              387.72,26.00 404.02,79.00 404.02,79.00
              404.02,79.00 412.00,108.00 412.00,108.00
              412.00,108.00 396.00,108.00 396.00,108.00
              396.00,108.00 396.00,106.00 396.00,106.00
              396.00,106.00 400.00,106.00 400.00,106.00
              400.00,106.00 393.02,82.00 393.02,82.00
              393.02,82.00 384.00,54.00 384.00,54.00
              384.86,72.77 389.12,99.53 365.00,106.07
              357.47,108.12 346.84,108.00 339.00,108.00
              339.00,108.00 342.17,101.96 342.17,101.96
              342.17,101.96 351.42,72.00 351.42,72.00
              351.42,72.00 363.14,34.00 363.14,34.00
              363.14,34.00 369.97,10.00 369.97,10.00
              369.97,10.00 368.00,1.00 368.00,1.00 Z
            M 194.00,4.00
            C 194.00,4.00 193.00,4.00 193.00,4.00
              193.00,4.00 194.00,5.00 194.00,5.00
              194.00,5.00 194.00,4.00 194.00,4.00 Z
            M 193.00,5.00
            C 182.86,8.97 176.48,20.85 173.90,31.00
              168.50,52.18 169.38,88.35 189.00,102.36
              196.20,107.51 199.67,105.43 205.99,100.33
              214.08,93.81 219.20,83.50 217.56,73.00
              215.63,60.64 207.56,47.67 201.20,37.00
              197.53,30.85 191.97,21.18 191.61,14.00
              191.46,10.99 192.41,7.95 193.00,5.00 Z
            M 372.00,14.00
            C 372.00,14.00 363.33,40.00 363.33,40.00
              363.33,40.00 343.00,106.00 343.00,106.00
              353.13,106.00 364.38,106.83 372.91,100.21
              383.39,92.08 383.02,77.83 383.00,66.00
              382.98,53.64 376.82,25.34 372.00,14.00 Z
            M 384.00,51.00
            C 384.00,51.00 383.00,51.00 383.00,51.00
              383.00,51.00 384.00,52.00 384.00,52.00
              384.00,52.00 384.00,51.00 384.00,51.00 Z
            M 311.00,93.00
            C 313.31,88.46 314.61,84.12 314.91,79.00
              315.44,69.92 311.92,55.17 301.00,54.11
              289.93,53.03 284.65,63.39 287.85,73.00
              291.00,82.45 301.71,90.34 311.00,93.00 Z
            M 190.00,112.00
            C 190.00,112.00 189.00,112.00 189.00,112.00
              189.00,112.00 190.00,113.00 190.00,113.00
              190.00,113.00 190.00,112.00 190.00,112.00 Z">
            </path>
            </svg>
        </Container>
    )
}