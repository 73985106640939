import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {
  Link,
  useNavigate,

} from "react-router-dom";


import React, { useContext } from "react";
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { Button } from 'react-bootstrap';
import { AuthContext } from './Auth';

export const CustomNavbar = () => {

    const currentUser = useContext(AuthContext);
    const navigate = useNavigate();


    const logoutButton = () => {
        currentUser.logout().then(()=>{
          console.log('Logged out')
        }).finally(()=>{
          navigate("/login")
        })
    }

  return (
    <div>
        <Navbar fixed="top" className="bg-body-tertiary">
          
          <Container xs="auto">

            <Navbar.Brand as={Link} to={"/home".replace(':id', '')} className='fancy-navbar-item'>Réka & Dániel</Navbar.Brand>
            
            {currentUser.currentUser &&
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to={"/home"} className='fancy-navbar-item' hidden={true}>Esküvő</Nav.Link>
                <Nav.Link as={Link} to={"/hotel"} className='fancy-navbar-item' hidden={false}>Szállás</Nav.Link>


              </Nav>
            </Navbar.Collapse>
            }
            {currentUser.currentUser &&
                <NavbarCollapse className="justify-content-end">
                    <Button type="submit" onClick={logoutButton} style={{fontSize:'10px'}}>Kijelentkezés</Button>
                </NavbarCollapse>
            }
            
          </Container>
        </Navbar>
    </div>
  )
}
