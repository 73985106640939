import React from 'react'
import Container from 'react-bootstrap/esm/Container'

import { HomeContentMobile } from './HomeContentMobile';
import { HomeAlternative } from './HomeAlternative';



export const Home = () => {

  return (
    <Container fluid className='p-0 m-0'>

      <Container fluid className='p-0 m-0 d-sm-block d-none'>
        <HomeAlternative></HomeAlternative>
      </Container>

      <Container  className='p-0 m-0 d-sm-none d-block'>
        <HomeContentMobile className='p-0 m-0'/>
      </Container>

    </Container>

  )
}
