import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentContext } from '../ContentProvider';
import { QATitle } from '../titles/QATitle';


export const QA = () => {
  const content = useContext(ContentContext);

  return (

    <Container fluid>
      <Row className='pt-1'>
      <QATitle/>
      </Row>
      <Row>
        {
          content.info?.qa?.map(function (qa_item) {
            return <Col xs={12} md={4}>
              <p style={{ fontWeight: 'bold' }}>{qa_item.question}</p>
              <p className="mt-2">{qa_item.answer}</p>
            </Col>;
          })
        }
      </Row>
    </Container>

  )
}
