import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";

import { Home } from './components/Home';

import React from "react";
import { Login } from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/Auth';
import { CookiesProvider } from 'react-cookie';
import { CustomNavbar } from './components/CustomNavbar';
import { ContentProvider } from './components/ContentProvider';
import { Accommodation } from './components/accomodations/Accommodation';

function App() {

  return (
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
      <AuthProvider>
        <ContentProvider>
          <BrowserRouter>
            <div className="App">

              <CustomNavbar></CustomNavbar>

              <div className='maxheight h-100'>

                <Routes>
                  <Route path='/login/:userId' element={<Login />} />
                  <Route exact path='/login/' element={<Login />} />
                  <Route exact path='/:userId' element={<Login />} />
                  <Route path='/' element={<Login />} />

                  <Route path='/home/' element={<PrivateRoute><Home /></PrivateRoute>}></Route>
                  <Route path='/hotel/' element={<PrivateRoute><Accommodation /></PrivateRoute>}></Route>

                </Routes>

              </div>

            </div>
          </BrowserRouter>
        </ContentProvider>
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;
