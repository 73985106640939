import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ContentContext } from '../ContentProvider';
import { InteractiveMap } from './InteractiveMap';
import { HelyszinTitle } from '../titles/HelyszinTitle';



export const Location = () => {
  const content = useContext(ContentContext);

  return (
    <Container fluid className='p-0'>
      <Row>
        <HelyszinTitle/>
        <p>{content?.info?.location}</p>
      </Row>
      <Row className='m-0 p-0 align-items-center'>
        <Col xs={12} s={6} md={6} className='p-0'>
          
          <InteractiveMap></InteractiveMap>

        </Col>
        <Col xs={12} s={6} m={2} md={6} className='mt-3'>
          <Container style={{ maxWidth: 700 }}>
            <p>{content?.info?.location_map}</p>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}
