import Modal from 'react-bootstrap/Modal';

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import img from '../../../images/vacsora.jpg'


export const Dinner = (props) => {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Vacsora
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 pb-2 m-0'>
        <Image src={img} style={{width:'100%'}}></Image>
        <Carousel.Caption>
              <h3>Svédasztalos vacsora.</h3>
            </Carousel.Caption>
      </Modal.Body>
    </Modal>
  );
}

