import React from 'react'
import Container from 'react-bootstrap/esm/Container'

import { Welcome } from './homepage/Welcome';
import { Location } from './homepage/Location'
import { Schedule } from './homepage/Schedule'

import { Image } from 'react-bootstrap';
import { Accommodation } from './accomodations/Accommodation';
import { Contact } from './Contact';
import { QA } from './homepage/QA';

import img from '../images/divider_orange.jpg'
import landing_img from '../images/mobile_landing_small.png'
import img_volgymajor from '../images/volgymajor_mobile.png'



export const HomeContentMobile = () => {

  return (

    <Container className='p-0 m-0' style={{
                  backgroundImage: 'linear-gradient(#E3C28D, #E3C28D)', 
                  backgroundSize: "cover", 
                  overflow: "hidden", 
                  height: "100%" , color:"#6A645A"}}>

      
      <div className='pb-5 m-0' style={{position: 'relative', height:'200px',  width: '100%'}}>
        <div style={{zIndex:4, position: 'absolute', alignItems:'bottom'}}>
          <Image src={landing_img} width= '100%'></Image>
        </div>

        <svg height="200px" width="100%" viewBox="0 0 100 100"  preserveAspectRatio="none">
          <path d="M10 0 L50 0 L100 80  L60 80 Z" fill='#C18845' />
          <path d="M-10 0 L40 0 L90 80  L40 80 Z" fill='#5D7052' />
        </svg>
        
      </div>

      <div className='pt-5'>
        <Container>
          <Welcome className='p-3 m-2'/>
        </Container>
        <Image src={img_volgymajor} style={{position: 'relative', maxHeight:'200px',  width: '100%'}}></Image>

        <Accommodation className='' />

        <Image src={img} style={{width: "70%", maxHeight: '80px', maxWidth: '500px'}}/>
        <Location className='mt-1' />
        <Image src={img} style={{width: "70%", maxHeight: '80px', maxWidth: '500px'}}/>
        <Schedule className='mt-1' />
        <Image src={img} style={{width: "70%", maxHeight: '80px', maxWidth: '500px'}}/>
        <QA className='pb-5' />
      </div>

      <div style={{ height: "500px" }}>
        <Contact></Contact>
      </div>
    </Container>



  )
}
