import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./Auth";

const PrivateRoute = ({children}) => {
    const {currentUser} = useContext(AuthContext);
    const authed = currentUser
    return authed ? children : <Navigate to="/login"/>
};

export default PrivateRoute;