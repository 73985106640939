import Modal from 'react-bootstrap/Modal';
import { ContentContext } from '../../ContentProvider';
import { useContext } from 'react';
import img from '../../../images/parkolo.jpg'

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

export const ParkingLot = (props) => {
  const content = useContext(ContentContext);


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Parkolás
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0 pb-2 m-0'>
        <Image src={img} style={{width:'100%'}}></Image>
        <Carousel.Caption>
              <h3>{content.info.parking}</h3>
            </Carousel.Caption>
      </Modal.Body>
    </Modal>
  );
}

