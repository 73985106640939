import React, { useEffect, useRef, useState } from 'react'
import { Button, Container, Image } from 'react-bootstrap'
import img_volgymajor_map_mobile from '../../images/volgymajor_map_mobile_empty.png'
import {ParkingLot} from './map/ParkingLot'
import {Dinner} from './map/Dinner'
import {Koborlo} from './map/Koborlo'
import {Kapolna} from './map/Kapolna'
import {Macska} from './map/Macska'


export const InteractiveMap = () => {

  const [parkingShow, setParkingShow] = useState(false);
  const [koborloShow, setKoborloShow] = useState(false);
  const [macskaShow, setMacskaShow] = useState(false);
  const [vacsoraShow, setVacsoraShow] = useState(false);
  const [kapolnaShow, setKapolnaShow] = useState(false);

  const contianerRef = useRef();


  const [locP, setLocP] = useState({x:'0px',y:'0px'});
  const [locKo, setLocKo] = useState({x:'0px',y:'0px'});
  const [locM, setLocM] = useState({x:'0px',y:'0px'});
  const [locV, setLocV] = useState({x:'0px',y:'0px'});
  const [locKa, setLocKa] = useState({x:'0px',y:'0px'});


  useEffect(()=>{
    if (!contianerRef.current) return;
    const resizeObserver = new ResizeObserver(()=>{
        setLocP({x: `${contianerRef.current.height/3.4}px`, y: `${contianerRef.current.width/8}px`})
        setLocKo({x: `${contianerRef.current.height/1.55}px`, y: `${contianerRef.current.width/3.5}px`})
        setLocM({x: `${contianerRef.current.height/1.7}px`, y: `${contianerRef.current.width/2}px`})
        setLocV({x: `${contianerRef.current.height/2.5}px`, y: `${contianerRef.current.width/1.6}px`})
        setLocKa({x: `${contianerRef.current.height/1.6}px`, y: `${contianerRef.current.width/1.15}px`})
    })
    resizeObserver.observe(contianerRef.current);
    return () => resizeObserver.disconnect();
  }, [contianerRef]);

  return (
    <Container fluid className='p-0' >
        <p style={{color:'#8B0000'}}>Kattints a piros gombokra a térképen!</p>
       
        <Container fluid style={{position:'relative'}}>
            <Image className='p-0 m-0' ref={contianerRef}
                src={img_volgymajor_map_mobile} 
                style={{maxHeight:'400px', width:'100%'}}></Image>



            <Button
            onClick={()=>{setParkingShow(true)}}
            variant='danger'
            style={{fontSize:'11px', 
            backgroundColor:'rgba(255, 0, 0, 0.7)',
            position: 'absolute',
            top: `${locP.x}`, 
            left:`${locP.y}`}}>
                P
            </Button>

            <Button
            onClick={()=>{setKoborloShow(true)}}
            variant='danger'
            style={{fontSize:'11px', 
            backgroundColor:'rgba(255, 0, 0, 0.7)',
            position: 'absolute',
            top: `${locKo.x}`, 
            left:`${locKo.y}`}}>
                K
            </Button>

            <Button
            onClick={()=>{setMacskaShow(true)}}
            variant='danger'
            style={{fontSize:'11px', 
            backgroundColor:'rgba(255, 0, 0, 0.7)',
            position: 'absolute',
            top: `${locM.x}`, 
            left:`${locM.y}`}}>
                M
            </Button>

            <Button
            onClick={()=>{setVacsoraShow(true)}}
            variant='danger'
            style={{fontSize:'11px', 
            backgroundColor:'rgba(255, 0, 0, 0.7)',
            position: 'absolute',
            top: `${locV.x}`, 
            left:`${locV.y}`}}>
                V
            </Button>

            <Button
            onClick={()=>{setKapolnaShow(true)}}
            variant='danger'
            style={{fontSize:'11px', 
            backgroundColor:'rgba(255, 0, 0, 0.7)',
            position: 'absolute',
            top: `${locKa.x}`, 
            left:`${locKa.y}`}}>
                K
            </Button>
        </Container>

        <ParkingLot
        show={parkingShow}
        onHide={() => setParkingShow(false)}/>

        <Dinner
        show={vacsoraShow}
        onHide={() => setVacsoraShow(false)}/>

        <Macska 
        show={macskaShow}
        onHide={() => setMacskaShow(false)}/>

        <Koborlo 
        show={koborloShow}
        onHide={() => setKoborloShow(false)}/>

        <Kapolna 
        show={kapolnaShow}
        onHide={() => setKapolnaShow(false)}/>
    
    </Container>
  )

}
