import React, { useContext } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

import { ContentContext } from '../ContentProvider';

import img from '../../images/map_kaboca_vendeghaz.png'


export const Kaboca = () => {
  const content = useContext(ContentContext);

  return (
    <Container fluid className='p-1'>
      <Row className='pt-1'>
        <Col className='mx-2'>
          <Container fluid className='p-3 ml-3'>
            <p>{content.accommodation.intro}</p>
            <p>{content.accommodation.parking}</p>

            {content.accommodation.prepayed ?
              <p>A szállásért nem kell fizetni, kérünk ha a recepción elkevertek volna valamit hívjatok azonnal!</p> :
              <p>A szállás ára {content.accommodation.price}, ezt a recepción tudjátok rendezni.</p>
            }

            {content.accommodation?.cancellation}

          </Container>

        </Col>
        <Col sm>
          <Image width={"100%"} src={img} style={{ maxHeight: "400px", maxWidth: "500px" }}></Image>
        </Col>
      </Row>

    </Container>
  )
}
